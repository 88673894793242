const content = {
  title: 'Absicherung für  Mitarbeiter - Betriebliche Altersvorsorge (bAV)',
  headvisualHeadline:
    'Absicherung für  Mitarbeiter - Betriebliche Altersvorsorge (bAV)',
  sectionHead: {
    headline: 'Betriebliche Altersvorsorge',
    paragraph:
      'Betriebliche Altersversorgung ist heute wichtiger denn je, denn sie ist ein Teil der sozialen Verantwortung des Arbeitgebers. Mit einer betrieblichen Altersversorgung bieten Sie als Arbeitgeber Ihren Mitarbeitern mehr: eine Vorsorgelösung fürs Alter. Gerade in Zeiten des Fachkräftemangels ist es für Sie wichtig sich als attraktiver Arbeitgeber zu präsentieren. Eine Möglichkeit ist das Angebot einer betrieblichen Altersvorsorge für Ihre Mitarbeiter. Durch Ihre Unterstützung kann sich Ihr Mitarbeiter eine zusätzliche Rente für später aufbauen. Damit können Sie die Motivation Ihrer Mitarbeiter steigern und die Bindung an Ihr Unternehmen erhöhen.'
  },
  halvedContent: [
    {
      background: 'light-grey-blue',
      headline: 'Direktversicherung',
      paragraph:
        'Der Klassiker der betrieblichen Altersversorgung. Bei diesem Durchführungsweg schließen Sie per Einzel- oder Gruppenvertrag eine Lebensversicherung für Ihre Mitarbeiter ab.',
      button: {
        link:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-direktversicherung',
        text: 'Zur Direktversicherung'
      }
    },
    {
      background: 'light-steel-blue',
      headline: 'Unterstützungskasse',
      paragraph:
        'Eine Unterstützungskasse ist eine rechtlich selbstständige Versorgungseinrichtung eines oder mehrerer Unternehmen, zumeist in der Rechtsform eines eingetragenen Vereins. Besonders geeignet ist die Unterstützungskasse für Führungskräfte und Besserverdienende, da die Beitragshöhe nach oben nicht begrenzt ist.',
      button: {
        link:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-unterstuetzungskasse',
        text: 'Zur Unterstützungsklasse'
      }
    },
    {
      background: 'medium-steel-blue',
      headline: 'Pensionszusage',
      paragraph:
        'Die Pensions- oder Direktzusage ist die häufigste Form der betrieblichen Altersversorgung. Zur Zielgruppe der Pensionszusage zählen insbesondere Besserverdiener (auch Gesellschafter-Geschäftsführer und Vorstände von Kapitalgesellschaften). Besonders attraktiv für diesen Personenkreis ist die unbegrenzte Versorgungshöhe.',
      button: {
        link:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-pensionszusage',
        text: 'Zur Pensionszusage'
      }
    },
    {
      background: 'light-grey-blue',
      headline: 'Pensionsfonds',
      paragraph:
        'Der Pensionsfonds ist eine selbständige Versorgungs-einrichtung für betriebliche Altersversorgung. Der Pensionsfonds bietet lukrative Anlageformen im Aktienmarkt.',
      button: {
        link:
          '/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge/bav-pensionsfonds',
        text: 'Zu den Pensionsfonds'
      }
    }
  ]
};

export default content;
