import React from 'react';
import { graphql } from 'gatsby';
import BackgroundImage from 'gatsby-background-image';

import MainLayout from 'src/layout/MainLayout';
import { SectionHead, HalvedContent, ContentBox, Button } from 'src/components';

import content from 'src/content/unsere-loesungen/absicherung-fuer-mitarbeiter/betriebliche-altersvorsorge';
import subpageNavigations from 'src/data/subpage-navigations';

const BetrieblicheAltersvorsorgePage = ({ data }) => (
  <MainLayout
    title={content.title}
    headvisual={{
      image: data.headvisual,
      headline: content.headvisualHeadline
    }}
    subpageNavItems={subpageNavigations.absicherungFuerMitarbeiter}
    activeNavItem={1}
    useParentPathname
  >
    <section>
      <SectionHead headline={content.sectionHead.headline}>
        <p>{content.sectionHead.paragraph}</p>
      </SectionHead>
      {content.halvedContent.map((item, index) => (
        <HalvedContent
          key={index}
          align="center"
          swapped={(index + 1) % 2 === 0}
        >
          <BackgroundImage
            fluid={
              data[`halvedContentBackground${index + 1}`].childImageSharp.fluid
            }
          />
          <ContentBox
            background={item.background}
            headline={item.headline}
            wider
          >
            <p>{item.paragraph}</p>
            <Button href={item.button.link} variant="ghost">
              {item.button.text}
            </Button>
          </ContentBox>
        </HalvedContent>
      ))}
    </section>
  </MainLayout>
);

export default BetrieblicheAltersvorsorgePage;

export const query = graphql`
  {
    headvisual: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-smiles-and-crosses-arms.jpg" }
    ) {
      ...image
    }
    halvedContentBackground1: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-with-ipad.jpg" }
    ) {
      ...image
    }
    halvedContentBackground2: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-and-woman-sit-laughing-on-stairs.jpg" }
    ) {
      ...image
    }
    halvedContentBackground3: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "man-wears-grey-jacket-and-looks-good.jpg" }
    ) {
      ...image
    }
    halvedContentBackground4: file(
      sourceInstanceName: { eq: "images" }
      relativePath: { eq: "woman-wearing-blue-jacket.jpg" }
    ) {
      ...image
    }
  }
`;
